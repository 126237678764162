let selectedMap;
map.onclick = function (event) {
    let target = event.target;
    if (target.tagName != 'path') return;
    highlight(target);
}

function highlight(path) {
    if (selectedMap) {
        selectedMap.classList.remove('highlight');
    }
    selectedMap = path;
    selectedMap.classList.add('highlight');
}

let count;
map.addEventListener("mouseenter", function (event) {
    if (event.target !== count) {
        event.target.classList.add('hover');
    }
}, true);

map.addEventListener("click", function (event) {
    count = event.target;
    event.target.classList.remove('hover');

}, true);

const regions = {
    north:      `<a id="arkhangelsk" href="#carddep">Архангельская область</a>
                <a id="komi" href="#carddep">Республика Коми</a>
                <a id="lenin" href="#carddep">Ленинградская область</a>`,

    central:    `<div class = 'centralReg'> 
                <a id="kaluga" href="#carddep">Калужская область</a>
                <a id="kostroma" href="#carddep">Костромская область</a>
                <a id="smolensk" href="#carddep">Смоленская область</a>
                <a id="tambov" href="#carddep">Тамбовская область</a>
                <a id="kursk" href="#carddep">Курская область</a>
                <a id="yaroslav" href="#carddep">Ярославская область</a> </div>`,

    siberia:    `<a id="irkutsk" href="#carddep">Иркутская область</a>`,

    volga:      `<a id="penza" href="#carddep">Пензенская область</a>
                <a id="permian" href="#second-list">Пермский край</a>
                <a id="tatarstan" href="#second-list">Республика Татарстан</a>
                <a id="chuvashia" href="#carddep">Чувашская республика</a>`,

    ural:       `<a id="yamal" href="#carddep">ЯНАО</a>`,

    vostock:    `<a id="kamchatka" href="#carddep">Камчатский край</a> <a id="magadan" href="#carddep">Магаданская область</a>`,

    kavkaz:     `<p>В этом году без выборов, голосуйте за нас в следующем сентябре</p>`,

    south:      `<a id="krasnodar" href="#carddep">Краснодарский край</a>
                <a id="rostov" href="#carddep">Ростовская область</a>
                <a id="sevastopol" href="#second-list">Севастополь</a>`,
                
};

map.addEventListener('click', getMap);

function getMap(event) {
    let targetClickId = event.target.id;
    if (typeof regions[targetClickId] !== "undefined") {
        let html = regions[targetClickId];
        cities.innerHTML = html;
        console.log(html);
        cities.style.left = event.pageX + "px";
        cities.style.top = event.pageY + "px";
        smoothScrolling();
    }
    }

    function smoothScrolling() {
        const anchors = document.querySelectorAll('a[href*="#"]');

        for (let anchor of anchors) {
            anchor.addEventListener("click", function(event) {
                event.preventDefault();
                const blockID = anchor.getAttribute("href");
                document.querySelector(''+ blockID).scrollIntoView({
                   behavior: "smooth",
                   block: "start",
                })
            })
        }
    }
    // В маке behavior сосет
    smoothScrolling()