const depatylist = {
    arkhangelsk: {
        title: "В этом году в Архангельской области выбирают губернатора",
        name: "Кандидат от ЛДПР Пивков Сергей Анатольевич",
        description: "Руководитель фракции ЛДПР в Архангельском областном Собрании депутатов. Доцент кафедры государственного и муниципального управления и менеджмента. Родился в Новодвинске, 36 лет.",
        photo: '<img src="/src/img/pivkov.png" alt="Пивков">',
        url: {
            vk: '<a href="https://vk.com/sapivkov">Вконтакте</a>',
            fb: '<a href="https://ru-ru.facebook.com/sapivkov">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/s_a_pivkov/">Инстаграм</a>'
        },
    },
    komi: {
        title: "В этом году в республике Коми выбирают губернатора",
        name: "Кандидат от ЛДПР Никитин Андрей Андреевич",
        description: "Родился в Сыктывкаре. Депутат муниципального образования ГО «Усинск». Отец двоих детей, 30 лет.",
        photo: '<img src="/src/img/Коми-Губернатор-Никитин.png" alt="Пивков">',
        url: {
            vk: '<a href="https://vk.com/id5686131">Вконтакте</a>',
        }
    },
    lenin: {
        title: "В этом году в Ленинградской области выбирают губернатора",
        name: "Кандидат от ЛДПР Лебедев Андрей Ярославович",
        description: "Экс-депутат Государственной думы, депутат Законодательного собрания Ленинградской области. Женат, воспитывает двоих детей.",
        photo: '<img src="/src/img/Ленобласть-Губернатор-Лебедев.png" alt="Лебедев">',
        url: {
        },
    },
    yamal: {
        title: "В этом году в республике  выбирают депутатов Заксобрания",
        name: "Кандидаты от ЛДПР ",
        description: "Здесь будут кандидаты от ЛДПР",
        url: {
            vk: "vk.com",
            fb: "fb.com",
            inst: "null",
        }
    },

    kaluga: {
        title: "В этом году в Калужской области выбирают губернатора",
        name: "Кандидат от ЛДПР Опарышев Степан Степанович",
        description: "Родился в Обнинске, 33 года. Координатор Обнинского местного отделения ЛДПР, начальник отдела развития ООО «МироСтрой».",
        photo: '<img src="/src/img/Калуга-Губернатор-Опарышев.png" alt="Лебедев">',
        url: {
        }
    },
    kostroma: {
        title: "В этом году в Костромской области выбирают губернатора",
        name: "Кандидат от ЛДПР Федоров Руслан Владимирович",
        description: "30 лет, окончил Костромской государственный технологический университет и Институт Мировых Цивилизаций. Руководитель Костромского отделения ЛДПР.",
        photo: "<img src='/src/img/Фёдоров.svg' alt='Фёдоров'>",
        url: {
            vk: '<a href="https://vk.com/rus44rus">Вконтакте</a>',
            fb: '<a href="https://www.facebook.com/rus44rus">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/fedorov44rus/">Инстаграм</a>',
        }
    },
    smolensk: {
        title: "В этом году в Смоленской области выбирают губернатора",
        name: "Кандидат от ЛДПР Алексей Владимирович Островский",
        description: "Губернатор Смоленской области, экс-депутат Госдумы, женат, воспитывает двух детей. Член Высшего совета ЛДПР.",
        photo: "<img src='/src/img/Смоленск-Губернатор-Островский.png' alt='Островский'>",
        url: {
            vk: '<a href="https://vk.com/ostrovskylive1">Вконтакте</a>',
            inst: '<a href="https://www.instagram.com/always_open_heart/">Инстаграм</a>',
        }
    },
    tambov: {
        title: "В этом году в Тамбовской области выбирают главу администрации",
        name: "Кандидат от ЛДПР Телегин Игорь Вячеславович",
        description: "Депутат Тамбовской областной Думы, заместитель председателя комитета по бюджету, налогам и финансам. 18 лет состоит в ЛДПР.",
        photo: '<img src="/src/img/Тамбов-Губернатор-Телегин.png" alt="Телегин">',
        url: {
        }
    },
    kursk: {
        title: "В этом году в Курской области выбирают депутата Государственной думы",
        name: "Кандидат от ЛДПР Томанов Алексей Юрьевич",
        description: "39 лет, координатор Курского регионального отделения ЛДПР, депутат Курского городского Собрания депутатов.Женат, воспитывает двух детей. Имеет высшее образование, 16 лет состоит в ЛДПР.",
        photo: '<img src="/src/img/Томанов.svg" alt="Томанов">',
        url: {
            vk: '<a href="https://vk.com/tomanovldpr">Вконтакте</a>',
        },
    },
    yaroslav: {
        title: "В этом году в Ярославской области выбирают депутата Государственной Думы",
        name: "Кандидат от ЛДПР Лобанова Ирина Валерьевна ",
        description: "51 год, депутат Ярославской областной Думы. 22 года состоит в ЛДПР.",
        photo: '<img src="/src/img/Ярославль-ГД-Лобанова.png" alt="Лобанова">',
        url: {
            vk: '<a href="https://vk.com/id91284005">Вконтакте</a>',
        }
    },
    magadan: {
        title: "В этом году в Магаданском крае выбирают депутатов Законодательного собрания края",
        name: "Кандидаты от ЛДПР",
        description: "Здесь будут ФИО и фотографии кандидатов ЛДПР",
        photo: '<img src="/src/img/" alt="">',
        url: {
            vk: "vk.com",
            fb: "fb.com",
            inst: "null",
        }
    },
    irkutsk: {
        title: "В этом году в Иркутской области выбирают губернатора",
        name: "Кандидат от ЛДПР Духовников Андрей Михайлович",
        description: "Координатор Иркутского регионального отделения ЛДПР, депутат Ушаковского муниципального образования Иркутской области. Имеет высшее образование, женат, воспитывает двух детей.",
        photo: '<img src="/src/img/Иркутск-Губернатор-Духовников 1.png" alt="Духовников">',
        url: {
            vk: '<a href="https://vk.com/id472460022">Вконтакте</a>',
        }
    },
    penza: {
        title: "В этом году в Пензенской области выбирают губернатора",
        name: "Кандидат от ЛДПР Васильев Александр Евгеньевич",
        description: "Руководитель фракции ЛДПР в Законодательном Собрании Пензенской области. Женат, воспитывает двух детей",
        photo: '<img src="/src/img/Пенза-Губернатор-Васильев.png" alt="Васильев">',
        url: {
            vk: '<a href="https://vk.com/vasilev5800">Вконтакте</a>',
            fb: '<a href="https://facebook.com/profile.php?id=100003613715285">Фэйсбук</a>',
            inst: '<a href="https://instagram.com/vasilev58/">Инстаграм</a>'
        }
    },
    permian: {
        title: "В этом году в Пермском крае выборы губернатора",
        name: "Кандидат от ЛДПР Постников Олег Сергеевич",
        description: "55 лет, имеет высшее образование, депутат Законодательного Собрания Пермского края. Женат, воспитывает сына и дочь.",
        photo: '<img src="/src/img/Пермь-Губернатор-Постников.png" alt="Постников">',
        url: {
            vk: '<a href="https://vk.com/postnikov.ldpr">Вконтакте</a>',
            fb: '<a href="https://www.facebook.com/postnikov.ldpr">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/postnikov.ldpr/?hl=ru">Инстаграм</a>'
        },
        second: {
            title: "И выборы депутата Законодательного Собрания Пермского края",
            name: "Кандидат от ЛДПР Балашов Георгий Валерьевич",
            description: "38 лет, женат, воспитывает сына и двух дочерей. Закончил военный Пермский ВКИУ, возглавляет отдел ЖКХ Ленинского района Перми.",
            photo: '<img src="/src/img/Пермь-ЗС-Балашов.png" alt="Балашов">',
            url: {

            },
        },
    },
    tatarstan: {
        title: "В этом году в республике Татарстан выборы губернатора",
        name: "Кандидат от ЛДПР Сурчилов Владимир Валерьевич",
        description: "Координатор Татарстанского регионального отделения ЛДПР, 43 года. Высшее образование, в ЛДПР 5 лет.",
        photo: '<img src="/src/img/Татарстан-Сурчилов.png" alt="Сурчилов">',
        url: {
            vk: '<a href=https://vk.com/vova_c>Вконтакте</a>',
        },
        second: {
            title: "И выборы депутата Государственной думы",
            name: "Кандидат от ЛДПР Андрей Колосов",
            description: "Директор благотворительного фонда поддержки и сопровождения одаренных детей «Ингениум».",
            photo: '<img src="/src/img/Татарстан-ГД-Колосов.png" alt="Колосов">',
            url: {
                vk: '<a href=https://vk.com/a.kolosov2020>Вконтакте</a>',
                fb: null,
                // inst: null,
            },
        },
    },
    chuvashia: {
        title: "В этом году в Чувашской Республике выбирают губернатора",
        name: "Кандидат от ЛДПР Степанов Олег Константинович",
        description: "Руководитель фракции ЛДПР в Государственном Совете Чувашской Республики. Имеет высшее образование, 52 года.",
        photo: '<img src="/src/img/Чувашия-Губернатор-Степанов.png" alt="Степанов">',
        url: {
            vk: '<a href="https://vk.com/lakost86">Вконтакте</a>',
            fb: '<a href="https://www.facebook.com/KOStepanov">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/121konstantin/">Инстаграм</a>'
        }
    },
    kamchatka: {
        title: "В этом году в Камчатском крае выбирают губернатора",
        name: "Кандидат от ЛДПР Калашников Валерий Юрьевич",
        description: "В ЛДПР 9 лет, высшее образование, депутат Законодательного Собрания Камчатского края, координатор Камчатского регионального отделения ЛДПР, 41 год.",
        photo: '<img src="/src/img/Камчатка-Губернатор-Калашников.png" alt="Калашников">',
        url: {
            vk: '<a href=https://vk.com/v.kalashnikov79>Вконтакте</a>',
        },
    },
    hmao: {
        title: "В этом году в республике  выбирают губернатора",
        name: "Кандидат от  Андрей ",
        description: "Депутат МО ГО  Отец троих , бывший ",
        url: {
            vk: "vk.com",
            fb: "fb.com",
            inst: "null",
        }
    },
    krasnodar: {
        title: "В этом году в Краснодарском крае выбирают губернатора",
        name: "Кандидат от ЛДПР Тутушкин Иван Геннадьевич",
        description: "43 года, депутат Заксобрания Краснодарского края, координатор Краснодарского отделения ЛДПР. Окончил НГТУ, кандидат технических наук, офицер, женат, воспитывает пятерых детей.",
        photo: '<img src="/src/img/Краснодар-Губернатор-Тушкин.png" alt="Тутушкин">',
        url: {
            vk: '<a href=https://vk.com/tutushkinig>Вконтакте</a>',
        }
    },
    rostov: {
        title: "В этом году в Ростовской области выбирают губернатора",
        name: "Кандидат от ЛДПР Пятибратов Пётр Владимирович",
        description: "Координатор Ростовского регионального отделения ЛДПР. Занимается благотворительностью: помогает ветеранам Донского края, детскому дому в городе Шахты, участвовал в восстановлении храма в Ростове.",
        photo: '<img src="/src/img/Ростов-Пятибратов.png" alt="Журавлев">',
        url: {
            vk: '<a href="https://vk.com/id558234817">Вконтакте</a>',
            fb: '<a href="https://www.facebook.com/ppldpr">Фэйсбук</a>',
            inst: '<a href="https://www.instagram.com/petr_paytibratov_ldpr/">Инстаграм</a>'
        }
    },
    sevastopol: {
        title: "В этом году Севастополе выборы губернатора",
        name: "Кандидат от ЛДПР Журавлев Илья Григорьевич",
        description: "Координатор регионального отделения ЛДПР, депутат Законодательного собрания города Севастополя, отец.",
        photo: '<img src="/src/img/Журавлев.svg" alt="Журавлев">',
        url: {
            fb: '<a href="https://www.facebook.com/iliayzhuravlev">Фэйсбук</a>',
            inst: '<a href="https://instagram.com/il_zhuravlyov?igshid=a6oowq89d2yt">Инстаграм</a>',
        },
        second: {
            title: "И выборы депутата Заксобрания города Севастополя",
            name: "Кандидат от ЛДПР Гацолаев Сергей Викторович",
            description: "Уроженец Севастополя, выпускник военно-морского училища, завершил службу в звании майора. Участвовал в присоединении Крыма, будучи начальником штаба ополчения Ленинского района. В 2014 году вступил в ЛДПР.",
            photo: '<img src="/src/img/Севастополь-Заксоб-Гацолаев.png" alt="Колосов">',
            url: {
                vk: '<a href="https://vk.com/ldpr_sev">ВКонтакте</a>',
                fb: '<a href="https://www.facebook.com/profile.php?id=100043848290807">Фэйсбук</a>',
                inst: '<a href="https://instagram.com/gatsolaevsergei?igshid=e6nrq60x4gc9">Инстаграм</a>',
            },
        },
    },




}

cities.addEventListener('click', showDeputy);

function showDeputy(event) {

    let targetClickId = event.target.id;
    let title = document.querySelector(".depaty-list__title h2");
    let name = document.querySelector(".cards__title");
    let description = document.querySelector(".cards__description");
    let photo = document.querySelector(".depaty-list__photo");
    let vk = document.querySelector("#vk");
    let fb = document.querySelector("#fb");
    let inst = document.querySelector("#inst");
    // vk.style.display = 'block';
    // inst.style.display = 'block';

    let secondList = document.querySelector("#second-list");
    let secondTitel = document.querySelector("#second-titel");
    let secondName = document.querySelector("#second-name");
    let secondDescription = document.querySelector("#second-description");
    let secondPhoto = document.querySelector("#second-photo");
    let secondVk = document.querySelector("#second-vk");
    let secondFb = document.querySelector("#second-fb");
    let secondInst = document.querySelector("#second-inst");

    if (depatylist[targetClickId].second == undefined) {
        secondList.style.display = 'none';
    } else {
        secondList.style.display = 'block';
    }

    if (depatylist[targetClickId].url.inst == undefined) {
        inst.style.display = 'none';
    } else {
        inst.style.display = 'block';
    }

    if (depatylist[targetClickId].url.vk == undefined) {
        vk.style.display = 'none';
    } else {
        vk.style.display = 'block';
    }

    if (depatylist[targetClickId].url.fb == undefined) {
        fb.style.display = 'none';
    } else {
        fb.style.display = 'block';
    }

    if (depatylist[targetClickId].second.url.inst == undefined) {
        secondInst.style.display = 'none';
    } else {
        secondInst.style.display = 'block';
    }

    if (depatylist[targetClickId].second.url.vk == undefined) {
        secondVk.style.display = 'none';
    } else {
        secondVk.style.display = 'block';
    }

    if (depatylist[targetClickId].second.url.fb == undefined) {
        secondFb.style.display = 'none';
    } else {
        secondFb.style.display = 'block';
    }

    title.innerText = depatylist[targetClickId].title;

    name.innerText = depatylist[targetClickId].name;
    description.innerText = depatylist[targetClickId].description;
    photo.innerHTML = depatylist[targetClickId].photo;
    vk.innerHTML = depatylist[targetClickId].url.vk;
    fb.innerHTML = depatylist[targetClickId].url.fb;
    inst.innerHTML = depatylist[targetClickId].url.inst;

    secondTitel.innerText = depatylist[targetClickId].second.title;
    secondName.innerText = depatylist[targetClickId].second.name;
    secondDescription.innerText = depatylist[targetClickId].second.description;
    secondPhoto.innerHTML = depatylist[targetClickId].second.photo;
    secondVk.innerHTML = depatylist[targetClickId].second.url.vk;
    secondFb.innerHTML = depatylist[targetClickId].second.url.fb;
    secondInst.innerHTML = depatylist[targetClickId].second.url.inst;

    // if (depatylist[targetClickId].url.vk === null) {
    //     vk.style.display = 'none';
    // }

    // if (depatylist[targetClickId].url.inst === null) {
    //     inst.style.display = 'none';
    // }

    // if (depatylist[targetClickId].second.url.inst === null) {
    //     inst.style.display = 'none';
    // }
}



